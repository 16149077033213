import styled from "@emotion/styled";
import UtilizeSpacer from "@/styles/theme-styles";
import defaultTheme from "@/styles/themes/default";
import { from } from "@/styles/responsive";
import { css } from "@emotion/react";

export const ContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  ${UtilizeSpacer({
    options: [
      {
        size: "lg",
        direction: [0, 2],
      },
      {
        size: "sm",
        direction: [1, 3],
      },
    ],
    type: "padding",
  })}
  ${from.laptop`
    min-width: 960px;
    width: 100vw;
  `}
`;

export const Container = styled.div``;

export const NPSHeader = styled.div`
  display: flex;
  height: 100%;
  width: 100vw;
  justify-content: center;
  background: ${defaultTheme.renewTheme.colors.night400};
  padding: 1rem 0 1rem 250px;
  p {
    margin: 0;
    padding: 0;
    color: ${defaultTheme.renewTheme.colors.white};
    text-align: center;
  }
  a {
    color: ${defaultTheme.renewTheme.colors.white};
    text-decoration: underline;
  }
`;

export const Main = styled.div<{ $background: boolean }>`
  display: flex;
  height: 100vh;
  background: ${defaultTheme.renewTheme.colors.white};
  ${({ $background }) =>
    $background &&
    css`
      background: ${defaultTheme.renewTheme.colors.arctic100};
    `}
`;
